import React, { Suspense, useEffect, useRef, useState } from "react";
import { getUserDataAPI } from "../../service/UserService";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import { dashboardBannerAPI } from "../../service/AuthService";
import {
  getAllMatchesOddsSoccerAPI,
  getAllMatchesOddsTennisAPI,
  getAllSoccerMatchListAPI,
  getAllTennisMatchListAPI,
} from "../../service/soccer&tennis";
import moment from "moment";
import {
  getAllCricketMatchListAPI,
  getAllMatchesOddsCricketAPI,
} from "../../service/cricket";
import { useParams } from "react-router-dom";

const Home = () => {
  const { game } = useParams();
  const userData = useSelector((state) => state.account.userData);
  const dispatch = useDispatch();
  const [matchList, setMatchList] = useState({
    cricket: [],
    soccer: [],
    tennis: [],
  });
  const [selectedGameType, setSelectedGameType] = useState(
    game ? game : "cricket"
  );
  const [gameData, setGameData] = useState([]);
  const getUserData = useCallback(async () => {
    if (userData.token) {
      var { response, code } = await getUserDataAPI(userData.id);
      if (code === 200) {
        dispatch({
          type: "accountData/setUserProfile",
          payload: response,
        });
      }
    }
  }, [userData.id]);
  const getBannerImages = async () => {
    var { response, code } = await dashboardBannerAPI();
    if (code === 200) {
      //setHomeImages(response);
    }
  };
  const getSoccerMatches = async () => {
    const { response, code } = await getAllMatchesOddsSoccerAPI();
    if (code == 200) {
      setGameData(response);
    }
  };
  const getTennisMatches = async () => {
    const { response, code } = await getAllMatchesOddsTennisAPI();
    if (code == 200) {
      setGameData(response);
    }
  };
  const getCricketMatches = async () => {
    const { response, code } = await getAllMatchesOddsCricketAPI();
    if (code == 200) {
      if (selectedGameType === "politics") {
        setGameData(response.filter((game) => game?.eventName?.includes("ELECTION")))
      } else {
        setGameData(response.filter((game) => !game?.eventName?.includes("ELECTION")))
      }
    }
  };
  useEffect(() => {
    let interval;

    getCricketMatches();

    if (selectedGameType === "soccer") {
      interval = setInterval(() => {
        getSoccerMatches();
      }, 2000);
    } else if (selectedGameType === "tennis") {
      interval = setInterval(() => {
        getTennisMatches();
      }, 2000);
    } else {
      interval = setInterval(() => {
        getCricketMatches();
      }, 2000);
    }
    return () => clearInterval(interval);
  }, [selectedGameType]);
  const getCricketData = async () => {
    const { response } = await getAllCricketMatchListAPI();
    setMatchList((prev) => ({ ...prev, cricket: response }));
  };
  const getSoccerData = async () => {
    const { response } = await getAllSoccerMatchListAPI();
    setMatchList((prev) => ({ ...prev, soccer: response }));
  };
  const getTennisData = async () => {
    const { response } = await getAllTennisMatchListAPI();
    setMatchList((prev) => ({ ...prev, tennis: response }));
  };
  useEffect(() => {
    if (userData) {
      getUserData();
    }
    getCricketData();
    getSoccerData();
    getTennisData();
  }, []);
  return (
    <>
      <div className="latest-event d-xl-none">
        {matchList?.cricket?.slice(0, 4)?.map((match) => (
          <div className="latest-event-item">
            <a
              className="blink_me"
              href={`/game/cricket/${match?.cricketId}`}
            >
              <i className="d-icon icon-40" />
              <span>{match?.eventName}</span>
            </a>
          </div>
        ))}
      </div>
      <ul className="nav nav-tabs d-xl-none menu-tabs">
        <li className="nav-item">
          <a className="nav-link" href="/sports-book/33">
            Lottery
          </a>
        </li>
        <li className="nav-item">
          <a aria-current="page" className="nav-link active" href="/home">
            Sports
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/casino-page">
            Our Casino
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/live-casino-list">
            Live Casino
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/slot-list">
            Slots
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/fantasy-list">
            Fantasy
          </a>
        </li>
      </ul>
      <div className="center-main-container home-page">
        <div className="center-container">
          <div className="latest-event d-none d-xl-flex">
            {matchList?.cricket
              ?.slice(
                0,
                matchList?.cricket?.length > 4
                  ? 4
                  : matchList?.cricket?.length
              )
              ?.map((match) => (
                <div className="latest-event-item ">
                  <a
                    className="blink_me"
                    href={`/game/cricket/${match?.cricketId}`}
                  >
                    <i className="d-icon me-1 icon-4" />
                    <span>{match?.eventName}</span>
                  </a>
                </div>
              ))}
          </div>
          <ul className="nav nav-pills sports-tab">
            <li className="nav-item">
              <a
                onClick={() => {
                  getCricketMatches();
                  setSelectedGameType("politics");
                }}
                className={`nav-link ${selectedGameType == "politics" &&
                  "active"}`}
              >
                <div className="d-xl-none">
                  <i className="icon icon-40" />
                </div>
                <span>Politics</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                onClick={() => {
                  getCricketMatches();
                  setSelectedGameType("cricket");
                }}
                className={`nav-link ${selectedGameType == "cricket" &&
                  "active"}`}
              >
                <div className="d-xl-none">
                  <i className="icon icon-4" />
                </div>
                <span>Cricket</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                onClick={() => {
                  getSoccerMatches();
                  setSelectedGameType("soccer");
                }}
                className={`nav-link ${selectedGameType == "soccer" &&
                  "active"}`}
              >
                <div className="d-xl-none">
                  <i className="icon icon-1" />
                </div>
                <span>Football</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                onClick={() => {
                  getTennisMatches();
                  setSelectedGameType("tennis");
                }}
                className={`nav-link ${selectedGameType == "tennis" &&
                  "active"}`}
              >
                <div className="d-xl-none">
                  <i className="icon icon-2" />
                </div>
                <span>Tennis</span>
              </a>
            </li>

            <li className="nav-item">
              <a className="nav-link">
                <div className="d-xl-none">
                  <i className="icon icon-66" />
                </div>
                <span>Kabaddi</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link">
                <div className="d-xl-none">
                  <i className="icon icon-68" />
                </div>
                <span>Esoccer</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link">
                <div className="d-xl-none">
                  <i className="icon icon-10" />
                </div>
                <span>Horse Racing</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link">
                <div className="d-xl-none">
                  <i className="icon icon-65" />
                </div>
                <span>Greyhound Racing</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link">
                <div className="d-xl-none">
                  <i className="icon icon-8" />
                </div>
                <span>Table Tennis</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link">
                <div className="d-xl-none">
                  <i className="icon icon-15" />
                </div>
                <span>Basketball</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link">
                <div className="d-xl-none">
                  <i className="icon icon-6" />
                </div>
                <span>Boxing</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link">
                <div className="d-xl-none">
                  <i className="icon icon-3" />
                </div>
                <span>Mixed Martial Arts</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link">
                <div className="d-xl-none">
                  <i className="icon icon-58" />
                </div>
                <span>American Football</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link">
                <div className="d-xl-none">
                  <i className="icon icon-18" />
                </div>
                <span>Volleyball</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link">
                <div className="d-xl-none">
                  <i className="icon icon-22" />
                </div>
                <span>Badminton</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link">
                <div className="d-xl-none">
                  <i className="icon icon-59" />
                </div>
                <span>Snooker</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link">
                <div className="d-xl-none">
                  <i className="icon icon-19" />
                </div>
                <span>Ice Hockey</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link">
                <div className="d-xl-none">
                  <i className="icon icon-11" />
                </div>
                <span>E Games</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link">
                <div className="d-xl-none">
                  <i className="icon icon-9" />
                </div>
                <span>Futsal</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link">
                <div className="d-xl-none">
                  <i className="icon icon-39" />
                </div>
                <span>Handball</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link">
                <div className="d-xl-none">
                  <i className="icon icon-52" />
                </div>
                <span>Motor Sports</span>
              </a>
            </li>
          </ul>
          <div className="tab-content mt-1">
            <div className="tab-pane active">
              <div className="bet-table">
                <div className="bet-table-header">
                  <div className="bet-nation-name">
                    <b>Game</b>
                  </div>
                  <div className="bet-nation-odd">
                    <b>1</b>
                  </div>
                  <div className="bet-nation-odd">
                    <b>X</b>
                  </div>
                  <div className="bet-nation-odd">
                    <b>2</b>
                  </div>
                </div>
                <div className="bet-table-body">
                  {gameData?.map((game, index) => (
                    <div className="bet-table-row">
                      <div className="bet-nation-name">
                        <a
                          className="bet-nation-game-name"
                          href={`/game/${selectedGameType.toLowerCase()}/${
                            game[selectedGameType.toLowerCase() + "Id"]
                          }
                   `}
                        >
                          <span>{game?.eventName}</span>
                          {moment(
                            game?.eventDate
                              ? game?.eventDate
                              : game?.eventTime
                          ).format("DD-MM-YYYY HH:mm A")}
                        </a>
                        <div className="game-icons">
                          <div className="game-icon">
                            {game?.inPlay && <span className="active" />}
                          </div>
                          <div className="game-icon">
                            <i className="fas fa-tv icon-tv" />
                          </div>
                          <div className="game-icon">
                            <img src={require("../../assets/ic_fancy.png")} />
                          </div>
                          <div className="game-icon">
                            <img src={require("../../assets/ic_bm.png")} />
                          </div>
                          <div className="game-icon" />
                        </div>
                      </div>
                      <div class="bet-nation-odd d-xl-none">
                        <b>1</b>
                      </div>
                      <div class="bet-nation-odd d-xl-none">
                        <b>X</b>
                      </div>
                      <div class="bet-nation-odd d-xl-none">
                        <b>2</b>
                      </div>
                      <div className="bet-nation-odd">
                        <div className="back odd-box">
                          <span className="bet-odd">
                            {game?.back1 ? game?.back1 : <b>-</b>}
                          </span>
                        </div>
                        <div className="lay odd-box">
                          <span className="bet-odd">
                            {game?.lay1 ? game?.lay1 : <b>-</b>}
                          </span>
                        </div>
                      </div>
                      <div className="bet-nation-odd">
                        <div className="back odd-box">
                          <span className="bet-odd">
                            {game?.back11 ? game?.back11 : <b>-</b>}
                          </span>
                        </div>
                        <div className="lay odd-box">
                          <span className="bet-odd">
                            {game?.lay11 ? game?.lay11 : <b>-</b>}
                          </span>
                        </div>
                      </div>
                      <div className="bet-nation-odd">
                        <div className="back odd-box">
                          <span className="bet-odd">
                            {game?.back12 ? game?.back12 : <b>-</b>}
                          </span>
                        </div>
                        <div className="lay odd-box">
                          <span className="bet-odd">
                            {game?.lay12 ? game?.lay12 : <b>-</b>}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {/*  <div className="casino-list mt-2">
            <div className="casino-list-item">
              <a href="/casino/superover3">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/superover3.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Mini Superover</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/goal">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/goal.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Goal</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/ab4">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/ab4.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">ANDAR BAHAR 150 cards</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/lucky15">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/lucky15.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Lucky 15</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/superover2">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/superover2.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Super Over2</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/teen41">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teen41.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">
                  Queen Top Open Teenpatti
                </div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/teen42">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teen42.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">
                  Jack Top Open Teenpatti
                </div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino-list/LC/4/31">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/roulette3.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Roulette</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/sicbo2">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/sicbo2.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Sic Bo2</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino-list/LC/4/31">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/roulette2.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Roulette</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/teen33">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teen33.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Instant Teenpatti 3.0</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/sicbo">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/sicbo.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Sic Bo</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/ballbyball">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/ballbyball.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Ball by Ball</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/teen32">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teen32.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Instant Teenpatti 2.0</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino-list/LC/4/31">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/roulette1.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Roulette</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/teen">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teen.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Teenpatti 1-day</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/teen20">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teen20.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">20-20 Teenpatti</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/teen9">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teen9.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Teenpatti Test</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/teen8">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teen8.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Teenpatti Open</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/poker">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/poker.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Poker 1-Day</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/poker20">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/poker20.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">20-20 Poker</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/poker6">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/poker6.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Poker 6 Players</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/baccarat">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/baccarat.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Baccarat</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/baccarat2">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/baccarat2.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Baccarat 2</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/dt20">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/dt20.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">20-20 Dragon Tiger</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/dt6">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/dt6.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">1 Day Dragon Tiger</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/dtl20">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/dtl20.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">20-20 D T L</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/dt202">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/dt202.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">20-20 Dragon Tiger 2</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/card32">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/card32.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">32 Cards A</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/card32eu">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/card32eu.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">32 Cards B</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/ab20">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/ab20.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Andar Bahar</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/abj">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/abj.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Andar Bahar 2</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/lucky7">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/lucky7.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Lucky 7 - A</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/lucky7eu">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/lucky7eu.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Lucky 7 - B</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/3cardj">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/3cardj.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">3 Cards Judgement</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/war">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/war.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Casino War</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/worli2">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/worli.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Worli Matka</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/worli">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/worli2.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Instant Worli</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/aaa">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/aaa.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Amar Akbar Anthony</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/btable">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/btable.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Bollywood Casino</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/lottcard">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/lottcard.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Lottery</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/cricketv3">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/cricketv3.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">5Five Cricket</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/cmatch20">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/cmatch20.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Cricket Match 20-20</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/cmeter">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/cmeter.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Casino Meter</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/teen6">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teen6.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Teenpatti - 2.0</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/queen">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/queen.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Queen</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/race20">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/race20.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Race20</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/lucky7eu2">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/lucky7eu2.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Lucky 7 - C</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/superover">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/superover.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Super Over</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/trap">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/trap.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">The Trap</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/patti2">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/patti2.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">2 Cards Teenpatti</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/teensin">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teensin.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">29Card Baccarat</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/teenmuf">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teenmuf.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Muflis Teenpatti</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/race17">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/race17.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Race To 17</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/teen20b">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teen20b.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">20-20 Teenpatti B</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/trio">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/trio.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Trio</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/notenum">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/notenum.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Note Number</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/kbc">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/kbc.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">K.B.C</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/teen120">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teen120.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">1 CARD 20-20</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/teen1">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teen1.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">1 CARD ONE-DAY</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino-list/LC/4/31">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/roulette.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Roulette</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/ab3">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/ab3.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">ANDAR BAHAR 50 cards</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/aaa2">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/aaa2.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Amar Akbar Anthony 2</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/race2">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/race2.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Race to 2nd</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/teen3">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teen3.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Instant Teenpatti</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/dum10">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/dum10.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Dus ka Dum</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/cmeter1">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/cmeter1.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">1 Card Meter</div>
              </a>
            </div>
          </div> */}
          <div className="casino-list mt-2">
            {/* <div className="casino-list-item">
              <a href="/casino/ballbyball">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/ballbyball.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Ball by Ball</div>
              </a>
            </div> */}
            <div className="casino-list-item">
              <a href="/casino/superover">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/superover.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Super Over</div>
              </a>
            </div>

            <div className="casino-list-item">
              <a href="/casino/race20">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/race20.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Race20</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/cricketv3">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/cricketv3.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">5Five Cricket</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/abj">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/abj.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Andar Bahar 2</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/dt202">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/dt202.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">20-20 Dragon Tiger 2</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/baccarat">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/baccarat.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Baccarat</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/baccarat2">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/baccarat2.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Baccarat 2</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/lucky7eu">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/lucky7eu.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Lucky 7 - B</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/war">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/war.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Casino War</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/dtl20">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/dtl20.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">20-20 D T L</div>
              </a>
            </div>

            {/*   <div className="casino-list-item">
              <a href="/casino/poker6">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/poker6.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Poker 6 Players</div>
              </a>
            </div> */}

            <div className="casino-list-item">
              <a href="/casino/cmeter">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/cmeter.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Casino Meter</div>
              </a>
            </div>

            <div className="casino-list-item">
              <a href="/casino/teen8">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teen8.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Teenpatti Open</div>
              </a>
            </div>

            <div className="casino-list-item">
              <a href="/casino/teen20">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teen20.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">20-20 Teenpatti</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/queen">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/queen.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Casino Queen</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/card32">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/card32.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">32 Cards A</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/teen9">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teen9.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Teenpatti Test</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/dt6">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/dt6.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">1 Day Dragon Tiger</div>
              </a>
            </div>

            <div className="casino-list-item">
              <a href="/casino/teen">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/teen.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Teenpatti 1-day</div>
              </a>
            </div>

            <div className="casino-list-item">
              <a href="/casino/poker">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/poker.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Poker 1-Day</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/poker20">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/poker20.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">20-20 Poker</div>
              </a>
            </div>

            <div className="casino-list-item">
              <a href="/casino/dt20">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/dt20.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">20-20 Dragon Tiger</div>
              </a>
            </div>

            <div className="casino-list-item">
              <a href="/casino/card32eu">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/card32eu.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">32 Cards B</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/ab20">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/ab20.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Andar Bahar</div>
              </a>
            </div>

            <div className="casino-list-item">
              <a href="/casino/lucky7">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/lucky7.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Lucky 7 - A</div>
              </a>
            </div>

            <div className="casino-list-item">
              <a href="/casino/worli">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/worli2.jpg")',
                  }}
                />
                <div className="casino-list-name">Instant Worli</div>
              </a>
            </div>

            <div className="casino-list-item">
              <a href="/casino/aaa">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/aaa.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Amar Akbar Anthony</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/btable">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/btable.jpg"), url("https://dataobj.ecoassetsservice.com/casino-icons/default.jpg")',
                  }}
                />
                <div className="casino-list-name">Bollywood Casino</div>
              </a>
            </div>
            <div className="casino-list-item">
              <a href="/casino/cmatch">
                <div
                  className="casino-list-item-banner"
                  style={{
                    backgroundImage:
                      'url("https://dataobj.ecoassetsservice.com/casino-icons/lc/cmatch20.jpg")',
                  }}
                />
                <div className="casino-list-name">Cricket Match 20</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
